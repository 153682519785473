import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React from 'react'
import GearIcon from '../../assets/images/product/developer-experience/gear.svg'
import SpeechIcon from '../../assets/images/product/developer-experience/speech.svg'
import LampIcon from '../../assets/images/product/developer-experience/lamp.svg'
import WorkingAgreementsTag from '../../assets/images/product/developer-experience/working-agreements-tag.svg'
import SlackTag from '../../assets/images/product/developer-experience/slack-tag.svg'
import CiTag from '../../assets/images/product/developer-experience/ci-tag.svg'
import SurveysTag from '../../assets/images/product/developer-experience/surveys-tag.svg'
import Tag from '../../assets/images/product/developer-experience/developer-experience-tag.svg'
import MetaImage from '../../assets/images/product/developer-experience/meta.png'
import Layout from '../../components/Layout'
import IllustrationBlock from '../../components/blocks/IllustrationBlock'
import ColumnsBlock from '../../components/blocks/ColumnsBlock'
import HeroBlock from '../../components/blocks/HeroBlock'
import LeadBlock from '../../components/blocks/LeadBlock'
import QuoteBlock from '../../components/blocks/QuoteBlock'
import Blurb from '../../components/system/Blurb'
import Box from '../../components/primitives/Box'
import CTABlock from '../../components/blocks/CTABlock'
import VideoBlock from '../../components/blocks/VideoBlock'
import { FeaturedBlogArticles } from '../../components/changelog/FeaturedBlogArticles'
import GlobalStyleReset from '../../components/system/GlobalStyleReset'
import { responsiveScale } from '../../styles/helpers'

export const query = graphql`
  {
    backgroundImage: file(
      relativePath: { eq: "images/product/developer-experience/hero.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    videoThumbnail: file(
      relativePath: {
        eq: "images/product/developer-experience/video-thumbnail.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1280)
      }
    }
    workingAgreements: file(
      relativePath: {
        eq: "images/product/developer-experience/working-agreements.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1000)
      }
    }
    slack: file(
      relativePath: { eq: "images/product/developer-experience/slack.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1000)
      }
    }
    ci: file(
      relativePath: { eq: "images/product/developer-experience/ci.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1000)
      }
    }
    surveys: file(
      relativePath: { eq: "images/product/developer-experience/surveys.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1000)
      }
    }
  }
`

const ExperiencePage = () => {
  const data = useStaticQuery(query)

  return (
    <Layout
      title="Developer experience"
      variant="dark"
      isNew
      description="Give your software teams the tools they need to improve the experience of shipping software at your company."
      metaImage={MetaImage}
    >
      <GlobalStyleReset>
        <HeroBlock
          tag={Tag}
          title="Create a better developer experience with great tooling "
          content={
            <>
              Give your software teams the tools they need to improve the
              experience of shipping software.
            </>
          }
          backgroundImage={getImage(data.backgroundImage)!}
        />
        <LeadBlock
          heading={<>Minimize interruptions and&nbsp;maximize&nbsp;flow</>}
          content="Frequent interruptions, long wait times, and flaky builds have the potential to derail developer experience. Swarmia helps you make meaningful improvements, one change at a time."
        />
        <VideoBlock
          paddingTop={responsiveScale(64)}
          paddingBottom={responsiveScale(32)}
          videoId="Cg4k8re7m9Q"
          thumbnail={getImage(data.videoThumbnail)!}
        />
        <ColumnsBlock
          paddingTop={responsiveScale(32)}
          paddingBottom={responsiveScale(64)}
          columns={[
            <Blurb
              key="1"
              title="Increase transparency"
              text="Give teams access to all the data and insights they need to make better decisions."
              icon={LampIcon}
            />,
            <Blurb
              key="2"
              title="Automate manual processes"
              text="Free up engineers’ time by automating work-in-progress updates and code review requests."
              icon={GearIcon}
            />,
            <Blurb
              key="3"
              title="Drive meaningful change"
              text="Use developer surveys to direct your improvement efforts at the highest-impact areas."
              icon={SpeechIcon}
            />,
          ]}
        />
        <QuoteBlock person="svyat" />
        <LeadBlock
          icon={WorkingAgreementsTag}
          heading={<>Put continuous improvement on&nbsp;autopilot</>}
          content="Use the working agreements in Swarmia to adopt healthy habits and remain accountable to the targets you’ve adopted as a team."
          link={{
            title: 'Read more about working agreements',
            href: '/product/working-agreements/',
          }}
        />
        <Box>
          <IllustrationBlock
            paddingTop={responsiveScale(32)}
            paddingBottom={responsiveScale(64)}
            image={getImage(data.workingAgreements)!}
            noPlaceHolder
            rounded
            shadow
          />
        </Box>
        <LeadBlock
          icon={SlackTag}
          heading="Speed up development workflows"
          content="Never let PRs fall through the cracks again. Use the notifications to automate feedback loops, speed up code reviews, and more."
          image={getImage(data.slack)!}
          link={{
            title: 'Read more about Slack notifications',
            href: '/github-slack-integration/',
          }}
        />
        <LeadBlock
          icon={CiTag}
          heading="Reduce CI wait times and costs"
          content="Find ways to speed up your CI/CD pipelines by drilling down into individual repositories and workflows."
          link={{
            title: 'Read more about CI insights',
            href: '/product/ci-cd-insights/',
          }}
        />
        <Box>
          <IllustrationBlock
            paddingTop={responsiveScale(32)}
            paddingBottom={responsiveScale(64)}
            image={getImage(data.ci)!}
            noPlaceHolder
            rounded
            shadow
          />
        </Box>
        <LeadBlock
          icon={SurveysTag}
          heading="Get actionable feedback from your engineers"
          content="Complement system metrics with sentiment data from your software engineers and use those insights to improve developer experience."
          link={{
            title: 'Read more about surveys',
            href: '/product/surveys/',
          }}
        />
        <Box>
          <IllustrationBlock
            paddingTop={responsiveScale(32)}
            paddingBottom={responsiveScale(64)}
            image={getImage(data.surveys)!}
            noPlaceHolder
            rounded
            shadow
          />
        </Box>
        <QuoteBlock person="josep" />
        <Box paddingBottom={responsiveScale(32)}>
          <CTABlock
            title={<>Start creating a better developer experience today</>}
          />
          <FeaturedBlogArticles
            heading="More from the swarmia blog"
            articles={[
              '/blog/developer-experience-what-why-how/',
              '/blog/minimizing-noise-and-bias-in-developer-surveys/',
              '/blog/continuous-integration/',
            ]}
            newStyles
          />
        </Box>
      </GlobalStyleReset>
    </Layout>
  )
}

export default ExperiencePage
